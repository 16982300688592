// ** React Imports
import { useContext } from "react";
import { Link } from "react-router-dom";

// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Third Party Components
import styled, { css } from "styled-components";
import { useInView } from "react-intersection-observer";

// ** Utils & Helpers
import cdnPrefixer from "helpers/cdnPrefixer";

// ** Contexts
import authContext from "contexts/authContext";

import { StyledOverlay } from "styles/components/Overlay.styled";

// #####################################################

const avatarSizes = {
	xxlarge: 180,
	xlarge: 160,
	large: 140,
	mlarge: 130,
	medium: 120,
	base: 80,
	small: 50,
	xsmall: 40,
	xxsmall: 36,
};

const borderSizes = {
	xxlarge: 3,
	xlarge: 2,
	large: 2,
	mlarge: 2,
	medium: 2,
	base: 2,
	small: 1,
	xsmall: 1,
	xxsmall: 1,
};

// #####################################################

const AvatarContainer = styled.div`
	position: relative;
	width: ${({ size }) =>
		Number.isFinite(size) ? size : `${avatarSizes[size]}`}px;
	height: ${({ size }) =>
		Number.isFinite(size) ? size : `${avatarSizes[size]}`}px;
`;

const AvatarImageWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	overflow: hidden;
	transform: scale(1);

	${({ $customBorderRadius }) =>
		$customBorderRadius &&
		css`
			border-radius: ${$customBorderRadius};
		`};

	${({ $customBoxShadow }) =>
		$customBoxShadow &&
		css`
			box-shadow: ${$customBoxShadow};
		`};

	/*
	1.brak efektu poszarpania:
	kontener position absolute a w nim "box-shadow: inset px color"

	2.krawędzie mało poszarpane:
	background-color: color;
	border: px solid transparent; 

	3.poszarpane krawędzie bardzo:
	border: px solid color; */

	// sposób nr 2:
	background-color: ${({ theme: { colors } }) => colors.whiteTrueAlways};
	${({ border, size, $noBorder }) =>
		$noBorder
			? css``
			: border
			? css`
					border: ${Number.isInteger(border) ? `${border}px` : `3px`}
						solid transparent;
			  `
			: css`
					border: ${`${borderSizes[size]}px`} solid transparent;
			  `}
`;

const AvatarImage = styled.img`
	width: 100%;
	height: 100%;
	background-color: var(--dz-ref-color-gray100);
	${({ isBlurred }) =>
		isBlurred &&
		css`
			filter: blur(7px);
			filter: progid:DXImageTransform.Microsoft.Blur(pixelradius='7', shadowopacity='0.0');
			-ms-filter: blur(7px);
			-webkit-filter: blur(7px);
		`};
`;

// #####################################################

const Avatar = ({
	src,
	alt,
	size = "medium",
	additional = false,
	border,
	to,
	scrollForce,
	closeModal,
	customBorderRadius,
	customBoxShadow,
	noBorder,
	triggerOnce = true,
}) => {
	const isAuth = useContext(authContext);
	const isClient = useSelector((state) => state.global.isClient);

	const { ref, inView } = useInView({
		threshold: 0,
		triggerOnce: triggerOnce,
		rootMargin: "0px 0px 600px 0px",
		initialInView: !isClient,
	});

	const content = (
		<AvatarContainer size={size} ref={ref}>
			<AvatarImageWrapper
				border={border}
				size={size}
				$noBorder={noBorder}
				$customBorderRadius={customBorderRadius}
				$customBoxShadow={customBoxShadow}
			>
				{inView && (
					<AvatarImage
						src={cdnPrefixer(src)}
						alt={alt}
						isBlurred={!isAuth}
					/>
				)}
				<StyledOverlay />
			</AvatarImageWrapper>
			{additional && typeof additional === "function"
				? additional()
				: null}
		</AvatarContainer>
	);

	// #####################################################

	return to ? (
		<Link
			to={to}
			style={{ display: "inherit" }}
			onClick={() => {
				if (typeof closeModal === "function") {
					document.body.style.overflow = "unset";
					document.body.style.top = "";
				}
				if (scrollForce) {
					window.scrollTo(0, 0);
				}
			}}
		>
			{content}
		</Link>
	) : (
		content
	);
};

// #####################################################

Avatar.Container = AvatarContainer;
Avatar.Image = AvatarImage;

Avatar.displayName = "Avatar";

export { avatarSizes };
export default Avatar;
