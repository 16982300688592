import parseBool from "utils/parseBool";

const cdnMovUrl = process.env.RAZZLE_CDN_MOV_URL;
const cdnImgUrl = process.env.RAZZLE_CDN_IMG_URL;
const cdnRemoteUrls = parseBool(process.env.RAZZLE_CDN_REMOTE_URLS, false);

// NOTE(Przemek): Is this function deprecated/needed?

const trimLeftStr = (str, charlist) => {
	// eslint-disable-next-line no-param-reassign
	if (charlist === undefined) charlist = "s";
	return String(str).replace(new RegExp(`^[${charlist}]+`), "");
};

const cdnPrefixer = (url = "", type) => {
	if (url === null || url === "") return "";
	if (cdnRemoteUrls === true) return url;
	let urlObject = null;
	let finalUrl = null;
	try {
		urlObject = new URL(url);
	} catch (err) {
		// nope
	}
	if (urlObject !== null) {
		finalUrl = urlObject.pathname + urlObject.search;
	}
	if (type === "mov") {
		return `${cdnMovUrl}/${trimLeftStr(finalUrl || url, "/")}`;
	}
	
	return `${cdnImgUrl}/${trimLeftStr(finalUrl || url, "/")}`;
};

export default cdnPrefixer;
