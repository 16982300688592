export default (variable, def = null) => {
	if (variable === null) {
		return def;
	}
	if (
		variable === 1 ||
		variable === "1" ||
		variable === "yes" ||
		variable === "on" ||
		variable === "true"
	) {
		return true;
	}
	if (
		variable === 0 ||
		variable === "0" ||
		variable === "no" ||
		variable === "off" ||
		variable === "false"
	) {
		return false;
	}
	return Boolean(variable);
};
