// ** Third Party Components
import styled from "styled-components";

// #####################################################

export const StyledOverlay = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
`;

// #####################################################
